<template>
    <div id="RegisterDialog" class="component">
        <v-slide-y-transition appear>
            <v-card class="rounded-xl pa-4" :class="['elevation-12']">
                <v-toolbar color="white" flat dark>
                    <v-spacer></v-spacer>
                    <v-toolbar-title id="titulo-login" class="font-weight-light">
                        <transition name="fade" mode="out-in">
                            <span key="login" class="black--text">Bem-vindo ao ZNAP Planning</span>
                        </transition>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>

                <v-card-text>
                    <div>
                        <v-divider class="mt-n4 mb-4"></v-divider>
                        <!-- <p class="black--text body-2">Software customizável de planejamento orçamentário e financeiro para empresas</p>
                        <p class="black--text body-2">
                            Reflita dentro da ferramenta todos os pontos importantes do seu processo de planejamento atual.
                            A sua empresa não precisa se ajustar ao softaware, ele se ajusta totalmente a sua operação.
                        </p> -->
                        <validation-observer v-slot="{ handleSubmit }">
                            <form @submit.prevent="!forgotPassword ? handleSubmit(login) : handleSubmit(recoverPassword())">
                                <span  v-if="!lostPassModel" class="ml-1">{{ $vuetify.lang.t('$vuetify.emailLoginLabel') }}</span>
                                <validation-provider
                                    :rules="{required: true, email: true}"
                                    v-slot="{errors}"
                                    name="E-mail"
                                >
                                    <transition name="fade-field" mode="out-in">
                                        <v-text-field
                                            data-testid="email-input"
                                            color="primary"
                                            outlined
                                            ref="loginInput"
                                            v-model="email"
                                            :error-messages="errors"
                                            :placeholder="!autofilled ? ' ' : ''"
                                            :validate-on-blur="true"
                                            class="mt-2"
                                            v-if="!lostPassModel"
                                            :class="!lostPassModel ? 'mb-4' : 'mb-8'"
                                            hide-details
                                        />
                                    </transition>
                                </validation-provider>

                                <span v-if="!forgotPassword && !lostPassModel" class="ml-1">{{ $vuetify.lang.t('$vuetify.passLoginLabel') }}</span>
                                <validation-provider
                                    v-slot="{errors}"
                                    :rules="{
                                        required: true,
                                        regex: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})'
                                    }"
                                    :name="$vuetify.lang.t('$vuetify.password')"
                                >
                                    <transition name="fade-field" mode="out-in">
                                        <div class="password-wrapper" :class="!lostPassModel ? 'login-card':'lost-pass-card'">
                                            <v-text-field
                                                data-testid="password-input"
                                                color="primary"
                                                outlined
                                                ref="passwordInput"
                                                v-model="password"
                                                :type="showPassword ? 'text' : 'password'" 
                                                :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                                                @click:append="showPassword = !showPassword"
                                                :error-messages="errors"
                                                v-if="!forgotPassword && !lostPassModel"
                                                :placeholder="!autofilled ? ' ' : ''"
                                                :validate-on-blur="true"
                                                class="mt-2 mb-8"
                                                hide-details
                                            >
                                            </v-text-field>
                                            
                                            <!-- <v-select 
                                                outlined dense
                                                v-model="language"
                                                v-if="!lostPassModel"
                                                v-on:change="changeLanguage(language.value)"
                                                :items="locales"
                                                item-text="text"
                                                return-object
                                                :label="$vuetify.lang.t('$vuetify.selectLocale')"
                                            ></v-select> -->
                                        </div>
                                    </transition>
                                </validation-provider>

                                <div class="text-center">
                                    <transition name="fade-field" mode="out-in">
                                        <div>
                                            <v-btn
                                                rounded
                                                color="secondary"
                                                dark
                                                large
                                                v-if="!lostPassModel"
                                                class="my-2 mr-md-1"
                                                text
                                                @click="lostPassModel = true"
                                            >
                                                <span key="login"> Voltar </span>
                                            </v-btn>
                                            <v-btn
                                                data-testid="submit-button"
                                                rounded
                                                color="secondary"
                                                dark
                                                large
                                                v-if="!lostPassModel"
                                                class="my-2 ml-md-1" 
                                                type="submit"
                                                :loading="loading"
                                            >
                                                <span key="login" ref="signInBtn" v-if="!forgotPassword && !lostPassModel">{{ $vuetify.lang.t('$vuetify.signInBtn') }}</span>
                                                <span key="lostPass" v-if="forgotPassword && !lostPassModel">{{ $vuetify.lang.t('$vuetify.forgotPassBtn') }}</span>
                                            </v-btn>
                                            <p class="mt-5 text-decoration-underline text-center lost-pass-p" @click="forgotPassword = !forgotPassword">
                                                <transition name="fade" mode="out-in">
                                                    <v-row key="login" v-if="!forgotPassword && !lostPassModel"
                                                        class="d-flex justify-center align-center mx-0 mt-5 mb-4"
                                                    >
                                                        <span class="mx-5">
                                                            {{ $vuetify.lang.t('$vuetify.forgotPassLink') }}
                                                            <v-icon small class="mt-n1">mdi-arrow-right</v-icon>
                                                        </span>
                                                    </v-row>

                                                    <span key="lostPass" v-if="forgotPassword && !lostPassModel">{{ $vuetify.lang.t('$vuetify.returnToLogin') }}</span>
                                                </transition>
                                            </p>
                                        </div>
                                    </transition>
                                </div>
                                <div class="text-center">
                                    <transition name="fade-field" mode="out-in">
                                        <v-layout justify-center wrap>
                                            <v-flex xs6>
                                                <v-btn
                                                    rounded
                                                    color="primary"
                                                    dark
                                                    block
                                                    large
                                                    class="py-7 mt-14 mb-16" 
                                                    :loading="loading"
                                                    v-if="lostPassModel"
                                                    @click="loginWithAzure()"
                                                >
                                                    <span key="login" ref="signInBtn">Entrar</span>
                                                    <!-- <img src="/img/azure-logo.png" class="mx-2" width="80px"/> -->
                                                </v-btn>
                                            </v-flex>
                                            <v-flex xs8>
                                                <span
                                                    key="login"
                                                    class="grey--text text--darken-2 znap-login"
                                                    ref="signInBtn"
                                                    @click="lostPassModel = false"
                                                    v-if="lostPassModel"
                                                >
                                                    Entrar com uma conta ZNAP
                                                </span>
                                                <!-- <v-btn
                                                    rounded
                                                    dark
                                                    block
                                                    large
                                                    text
                                                    color="grey darken-3"
                                                    style="text-transform: none;"
                                                    @click="lostPassModel = false"
                                                    v-if="lostPassModel"
                                                    :loading="loading"
                                                > -->
                                                    <!-- <img src="/img/znap-home.png" class="mx-2" width="80px"/> -->
                                                <!-- </v-btn> -->
                                            </v-flex>
                                        </v-layout>
                                    </transition>
                                </div>
                            </form>
                        </validation-observer>

                        <!-- <p class="mt-5 text-decoration-underline text-center lost-pass-p" @click="lostPassModel = !lostPassModel">
                            <transition name="fade" mode="out-in">
                                <v-row key="login" v-if="!lostPassModel"
                                    class="d-flex justify-center align-center mx-0 mt-5 mb-4"
                                >
                                    <span class="mx-5">{{ $vuetify.lang.t('$vuetify.forgotPassLink') }}</span> -->
                                    <!-- <span class="mx-5" @click="$emit('RegisterSelected')">{{ $vuetify.lang.t('$vuetify.createAccount') }}</span> -->
                                <!-- </v-row>

                                <span key="lostPass" v-else>{{ $vuetify.lang.t('$vuetify.returnToLogin') }}</span>
                            </transition>
                        </p> -->
                    </div>
                </v-card-text>
            </v-card>
        </v-slide-y-transition>

        <PasswordDialog v-if="dialog"
            :accessPassword="password"
            :dialog="dialog"
            :user="{email}"
            @closePasswordDialog="dialog = false"
            @loginWithAccessPassword="loginWithAccessPassword"
        />
    </div>
</template>

<script>
import PasswordDialog from '@/components/PasswordDialog'
import { mapMutations, mapActions, mapState } from "vuex"
import { localize } from 'vee-validate'

export default {
    name: 'LoginDialog',

    components: {
        PasswordDialog
    },

    data() {
        return {
            email: '',
            password: '',
            showPassword: false,
            loading: false,
            lostPassModel: true,
            forgotPassword: false,
            language: {},
            locales:[
                { text: 'Português', value: 'pt' },
                // { text: 'English', value: 'en' },
                // { text: 'Español', value: 'es' }
            ],
            autofilled: false,
            dialog: false,
            expiredPasswordDialog: false,
        }
    },
    computed:{
        ...mapState('auth',{
            authStatus: 'status'
        })
    },

    created() {
        this.language = this.locales.find(locale => locale.value === this.$store.getters.getSystem.language)
        this.changeLanguage(this.language.value)
    },

    watch: {
        email: {
            immediate: true,
            handler(val) {
                if (val) this.autofilled = true
            }
        },

        password: {
            immediate: true,
            handler(val) {
                if (val) this.autofilled = true
            }
        }
    },

    methods: {
        ...mapMutations('auth', ['cleanStatus', 'setEnableSessionExpiredDialog', 'setLoadingAuth']),
        ...mapActions("auth", [ 'authenticate' ]),
        ...mapActions("app", ["loadMenu"]),

        async login() {
            const { email,password } = this

            this.loading = true

            try {
                this.setLoadingAuth(true)
                const res = await this.authenticate({ email, password })
                if (res) {
                    if (res.data.isTemporaryPassword) {
                        this.loading = false
                        this.dialog = true
                    } else {
                        this.setEnableSessionExpiredDialog(true)
                        this.setLoadingAuth(false)
                        this.$router.push('/modules')
                    }
                }
            } catch (err) {
                this.$fnError(err)
                this.loading = false
            }
        },

        recoverPassword() {
            if (!this.lostPassModel && this.email !== '') {
                this.loading = true
                this.$http.post(this.$ipUser + 'user/forgot-password', { email: this.email })
                .then((res)=>{
                    this.$toast.success(res.data.msg)
                    this.loading = false
                }).catch((err)=>{
                    this.loading = false
                    this.$fnError(err)
                })
            }
        },
        
        changeLanguage(language){
            this.$vuetify.lang.current = language
            this.$store.commit('setLanguage', language)
            
            let _language = ''
            if (language === 'pt') {
                _language = 'pt_BR'
            } else {
                _language = language
            }

            localize(_language)
        },

        async loginWithAccessPassword(e) {
            const email = e.email
            const password = e.accessPassword
            const payload = {
                email,
                password,
                newPassword: e.newPassword
            }

            try {
                const res = await this.authenticate({ email, password })
                if (res) {
                    const passwordRes = this.$http.patch(this.$ipUser + 'user/change-password', payload)
                    if (passwordRes) {
                        this.setLoadingAuth(false)
                        this.$router.push('/modules')
                    }
                }
            } catch (err) {
                this.$fnError(err)
                this.loading = false
            }
        },

        async loginWithAzure (){
            try {
                // window.location.href = this.$ipUser + 'user/login/azure'
                const payload = {
                    language: "pt",
                    module: "ZNAP_SECURITY",
                    system: "VENTURUS",
                    clientId: 11,
                    login_service: "azure",
                    url: window.location.href
                    }
                const res = await this.$http.post(this.$ipUser + 'user/login/save', payload)
                if (res) {
                    window.location.href = this.$ipUser + 'user/login/azure'
                }
            } catch (err) {
                this.$fnError(err)
                this.loading = false
            }
        }
    },
}
</script>

<style>
.lost-pass-p{
    cursor: pointer;
}

#LoginDialog {
    height: 100%;
}

#titulo-login {
    font-size: 135%;
}

.login-card {
    height: 88px;
    transition: all .75s ease;
}

.lost-pass-card{
    height: 0px;
    transition: all .75s ease;
}

.logo-login {
    height: 15%;
    width: auto;
}

.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    transition: opacity .32s ease-in-out;
}

.fade-leave-active {
    transition: opacity .32s ease-in-out;
    opacity: 0;
}

.fade-field-enter {
    opacity: 0;
}

.fade-field-enter-active {
    transition: opacity .28s ease-in-out;
}

.fade-field-leave-active {
    transition: opacity .28s ease-in-out;
    opacity: 0;
}

.znap-login {
    cursor: pointer;
}
</style>