<template>
    <div class="text-center">
        <v-dialog
            v-model="dialog"
            width="500"
            @click:outside="closePasswordDialog"
        >
            <v-card>
                <v-card-title class="headline white">
                    {{ $vuetify.lang.t('$vuetify.changePassword') }}

                    <v-spacer></v-spacer>

                    <v-icon @click="closePasswordDialog">
                        mdi-close
                    </v-icon>
                </v-card-title>

                <validation-observer v-slot="{ handleSubmit }" v-if="!accessPassword.length">
                    <form @submit.prevent="handleSubmit(changePassword)">
                        <v-card-text>
                            <v-container>
                                <validation-provider
                                    :rules="{required: true}"
                                    v-slot="{errors}"
                                    :name="$vuetify.lang.t('$vuetify.oldPassword')"
                                >
                                    <v-text-field color="primary"
                                        :label="$vuetify.lang.t('$vuetify.oldPassword')"
                                        prepend-icon="mdi-lock"
                                        :type="showOldPassword ? 'text' : 'password'" 
                                        :append-icon="showOldPassword ? 'mdi-eye-off' : 'mdi-eye'"
                                        @click:append="showOldPassword = !showOldPassword"
                                        v-model="oldPassword"
                                        :error-messages="errors"
                                    ></v-text-field>
                                </validation-provider>

                                <validation-provider
                                    :rules="{required: true}"
                                    v-slot="{errors}"
                                    :name="$vuetify.lang.t('$vuetify.newPassword')"
                                >
                                    <v-text-field color="primary"
                                        :label="$vuetify.lang.t('$vuetify.newPassword')"
                                        prepend-icon="mdi-lock-reset"
                                        :type="showNewPassword ? 'text' : 'password'" 
                                        :append-icon="showNewPassword ? 'mdi-eye-off' : 'mdi-eye'"
                                        @click:append="showNewPassword = !showNewPassword"
                                        v-model="newPassword"
                                        :error-messages="errors"
                                    ></v-text-field>
                                </validation-provider>
                            </v-container>
                        </v-card-text>

                        <v-card-actions class="px-6 pb-6">
                            <v-spacer></v-spacer>

                            <v-btn @click="closePasswordDialog"
                                color="primary"
                                class="mr-3"
                                text
                            >
                                {{ $vuetify.lang.t('$vuetify.cancel') }}
                            </v-btn>

                            <v-btn type="submit"
                                key="changePassword"
                                color="primary"
                                :loading="loading"
                                :disabled="!validPassword"
                            >
                                {{ $vuetify.lang.t('$vuetify.changePassword') }}
                            </v-btn>
                        </v-card-actions>
                    </form>
                </validation-observer>

                <validation-observer v-slot="{ handleSubmit }" v-else>
                    <form @submit.prevent="handleSubmit(loginWithAccessPassword)">
                        <v-card-text>
                            <v-container>
                                <validation-provider
                                    :rules="{required: true}"
                                    v-slot="{errors}"
                                    :name="$vuetify.lang.t('$vuetify.oldPassword')"
                                >
                                    <v-text-field color="primary"
                                        label="Senha de acesso temporária"
                                        prepend-icon="mdi-lock"
                                        :type="showOldPassword ? 'text' : 'password'" 
                                        :append-icon="showOldPassword ? 'mdi-eye-off' : 'mdi-eye'"
                                        @click:append="showOldPassword = !showOldPassword"
                                        v-model="accessPassword"
                                        :error-messages="errors"
                                    ></v-text-field>
                                </validation-provider>

                                <validation-provider
                                    :rules="{required: true}"
                                    v-slot="{errors}"
                                    :name="$vuetify.lang.t('$vuetify.newPassword')"
                                >
                                    <v-text-field color="primary"
                                        :label="$vuetify.lang.t('$vuetify.newPassword')"
                                        prepend-icon="mdi-lock-reset"
                                        :type="showNewPassword ? 'text' : 'password'" 
                                        :append-icon="showNewPassword ? 'mdi-eye-off' : 'mdi-eye'"
                                        @click:append="showNewPassword = !showNewPassword"
                                        v-model="newPassword"
                                        :error-messages="errors"
                                    ></v-text-field>
                                </validation-provider>
                            </v-container>
                        </v-card-text>

                        <v-card-actions class="px-6 pb-6">
                            <v-spacer></v-spacer>

                            <v-btn @click="closePasswordDialog"
                                color="primary"
                                class="mr-3"
                                text
                            >
                                {{ $vuetify.lang.t('$vuetify.cancel') }}
                            </v-btn>

                            <v-btn type="submit"
                                key="changePassword"
                                color="primary"
                                :loading="loading"
                                :disabled="!validPassword"
                            >
                                {{ $vuetify.lang.t('$vuetify.changePassword') }}
                            </v-btn>
                        </v-card-actions>
                    </form>
                </validation-observer>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: {
        dialog: { type: Boolean, required: true },
        user: { required: true },
        accessPassword: { type: String, required: false, default: '' }
    },

    data() {
        return {
            oldPassword: null,
            showOldPassword: false,
            newPassword: null,
            showNewPassword: false,
            loading: false,
        }
    },

    computed: {
        validPassword() {
            const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})")
            if(strongRegex.test(this.newPassword)) {
                return true
            }
            else {
                return false
            }
        }
    },

    methods: {
        closePasswordDialog() {
            this.$emit('closePasswordDialog')
            this.oldPassword = null
            this.newPassword = null
        },

        changePassword() {
            this.loading = true

            this.$http
                .patch(this.$ipUser + 'user/change-password', {
                    email: this.user.email,
                    password: this.oldPassword,
                    newPassword: this.newPassword
                })
                .then(res => {
                    // MSG0019 => 'Senha alterada com sucesso!'
                    if (res.data.cod === 'MSG0019') {
                        this.loading = false
                        this.closePasswordDialog()
                        this.$toast.success(res.data.msg)
                    } else {
                        this.loading = false
                        this.$toast.error(res.data.msg)
                    }
                })
                .catch(err => {
                    this.loading = false
                    this.$fnError(err)
                })
        },

        loginWithAccessPassword() {
            this.loading = true

            let payload = {
                ...this.user,
                accessPassword: this.accessPassword,
                newPassword: this.newPassword,
            }

            this.$emit('loginWithAccessPassword', payload)
        }
    }
}
</script>

<style>

</style>