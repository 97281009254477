<template>
    <div id="Login" class="view">
        <v-container fluid fill-height class="background-gradient">
            <v-layout justify-center align-end wrap>
                <v-flex xs12 sm12 md4>
                    <v-layout justify-center wrap>
                        <transition name="fade-img">
                            <img class="home-image my-4" :src="`/img/${getLogos.home}`" v-on:load="onLoaded" v-show="loaded">
                        </transition>
                    </v-layout>
                    <transition name="fade-dialog" v-if="!register">
                        <LoginDialog v-show="loaded"
                            @RegisterSelected="register = true"
                        >
                        </LoginDialog>
                    </transition>

                    <!-- <transition name="fade-dialog" v-else>
                        <RegisterDialog v-show="loaded"
                            @LoginSelected="register = false"
                        >
                        </RegisterDialog>
                    </transition> -->
                </v-flex>
                <v-footer class="footer-container transparent mt-4">
                    <span class="white--text mr-1">Powered by</span>
                    <span>
                        <a href="https://znap.com.br/" class="znap-link" target="_blank">ZNAP Technologies</a>
                    </span>
                    <span class="ml-1 text-caption white--text">®</span>
                    <span class="ml-1 text-caption white--text"> v. {{ appVersion }} </span>
                </v-footer>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import {version} from '../../package'
import { mapGetters } from 'vuex'
import LoginDialog from '@/components/LoginDialog'
// import RegisterDialog from '@/components/RegisterDialog'

export default {
    name: 'Login',

    components: {
        LoginDialog,
        // RegisterDialog
    },

    computed: {
        ...mapGetters('theme', ['getLogos']),
    },

    data() {
        return {
            loaded: false,
            register: false,
            appVersion: version
        }
    },

    methods:{
        onLoaded: function(){
            this.loaded = true
        }
    }
}
</script>

<style scoped>
@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fade-img-enter-active{
    animation-name: fadeInDown;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

.fade-dialog-enter-active{
    animation-name: fadeIn;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

.aparecer{
    display: block;
}

h1 {
    font-size: 50pt;
    text-shadow: 1px 5px 9px grey;
    font-weight: 100;
}

img{
    width: 300px;
    height: auto;
}

#Login {
    padding: 0px !important;
    height: 100%;
    width: 100%;
}

.home-image {
    width: auto;
    max-width: 300px;
    height: auto;
    max-height: 200px;
}

.footer-container {
    /* position: absolute; */
    bottom: 12px;
    /* display: flex; */
    justify-content: center;
    width: 99vw;
}

.znap-link {
    color: white;
    cursor: pointer;
    transition: all 200ms;
    text-decoration: none;
}

.znap-link:hover {
    color: #E71B7B;
    font-weight: bold;
}
</style>